import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { navigate } from "@reach/router";

const GoToListingPage = (parentPath, actionType) => {
  if (!actionType) {
    if (parentPath === 'settings') {
      navigate(`/${parentPath}`)
    } else {
      navigate(`/${parentPath}/list`)
    }
  } else {
    navigate(`/auth/login`)
  }
}

const ConditionalFormFooter = (props) => {
  const ResetFormFunc = () => {
    props.resetForm();
  }

  switch (props.actionType) {
    case "login":
      return (
        <>
          <li className="list-inline-item me-md-3 me-0">
            <i className="bi bi-save d-md-none d-inline-block icon-bootstrap-mobile"></i>
            <input type="submit" variant="primary" className="btn-primary-dashboard d-md-inline d-none" value="Login" />
          </li>
        </>
      )
      break;
    case "forgot-password":
      return (
        <>
          <li className="list-inline-item me-md-3 me-0">
            <i className="bi bi-save d-md-none d-inline-block icon-bootstrap-mobile"></i>
            <input type="submit" variant="primary" className="btn-primary-dashboard d-md-inline d-none" value="Send Recovery Link" />
          </li>
          <li className="list-inline-item">
            <i className="bi bi-x-square d-md-none d-inline-block icon-bootstrap-mobile"></i>
            <Button variant="secondary" className="btn-secondary-dashboard d-md-inline d-none" onClick={() => GoToListingPage(props.parentPath, props.actionType)}>Cancel</Button>
          </li>
        </>
      )
      break;
    case "reset-password":
      return (
        <>
          <li className="list-inline-item me-md-3 me-0">
            <i className="bi bi-save d-md-none d-inline-block icon-bootstrap-mobile"></i>
            <input type="submit" variant="primary" className="btn-primary-dashboard d-md-inline d-none" value="Change Password" />
          </li>
          <li className="list-inline-item">
            <i className="bi bi-x-square d-md-none d-inline-block icon-bootstrap-mobile"></i>
            <Button variant="secondary" className="btn-secondary-dashboard d-md-inline d-none" onClick={() => GoToListingPage(props.parentPath, props.actionType)}>Cancel</Button>
          </li>
        </>
      )
      break;
    default:
      return (
        <>
          {
            !props?.hideGoToListPage &&
            <li className="list-inline-item float-start">
              <i className="bi bi-x-square d-md-none d-inline-block icon-bootstrap-mobile"></i>
              <Button variant="primary" className="btn-primary-dashboard d-md-inline d-none" onClick={() => GoToListingPage(props.parentPath)}>Cancel</Button>
            </li>
          }
          {
            props?.tabs?.ShowPreviousTabBtn && (
              <li className="list-inline-item me-md-3 me-0 float-start">
                <i className="bi bi-save d-md-none d-inline-block icon-bootstrap-mobile"></i>
                <Button type="button" variant="primary" className="btn-primary-dashboard d-md-inline d-none"
                  disabled={props.tabs.isDisabled}
                  onClick={() => props.tabs.goToPrevious()}>Previous</Button>
              </li>
            )
          }
          <li className="list-inline-item me-0">
            <i className="bi bi-save d-md-none d-inline-block icon-bootstrap-mobile"></i>
            <Button type="submit" variant="primary" className="btn-primary-dashboard d-md-inline d-none">Save</Button>
          </li>

        </>
      )
      break;
  }
}

const FormFooter = (props) => {
  return (
    <Row className="btn_cls">
      <Col lg={12}>
        <hr />
        <ul className="list-inline text-end m-0">
          {ConditionalFormFooter(props)}
        </ul>
      </Col>
    </Row>
  )
}

export default FormFooter;