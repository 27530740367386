import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import RegionEdit from "@components/regions/Edit"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "regions.edit"
}

const RegionEditPage = (props) => (
  <>
    <Seo title="Edit Region" />
    <div className="main-wrapper">
      <Sidebar menuActive="settings" />
      <RegionEdit 
        menu="Edit Regions" 
        parentPath="regions" 
        pageType="Edit"
        additionalParams={AdditionalParams}/>
    </div>
  </>
)

export default RegionEditPage;
