import React, { useState } from "react";
import {Row, Col, Form} from "react-bootstrap";
import {HandleChange, CheckFieldValidity} from "../../utility/Form";
import CommonEditItemComp from "../common/Edit";
import FormFooter from "../common/FormFooter"
import {set, isEmpty, get} from "lodash";

const EditFormControls = (props) => {
    const [formError, setFromError] = useState({});
    const CheckFormFieldValidity = (event) => {
        const FieldError = CheckFieldValidity(event);
        setFromError({...formError, ...FieldError});
    }

    const HandleChangeFunc = (event) => {
        props.SetPublishActionAvailability(false);
        HandleChange(event);
        console.log('HandleChangeFunc', props.EditItem)
        if(!isEmpty(props.EditItem.published_at)){
            CheckFormFieldValidity(event);
        }
    }

    const FormSubmitFunc = async (event, additionalParams = {}) => {
        try {
            const Result = await props.HandleSubmitFunc(event, {
                formId: 'edit-region-form',
                ...additionalParams,
                enableValidation: !isEmpty(props?.EditItem?.published_at)
            })
            console.log('FormSubmitFunc', Result)
            if(get(Result, 'formSubmit.hasError')){
                setFromError(Result.formSubmit.error);
            }
        } catch (error) {
            console.log('FormSubmitFunc error', error);
        }
    }   

    const ResetForm = () => {
        //document.getElementById('edit-region-form').reset();
        window.location.reload();
    } 

    return (
        <>
            <Form id="edit-region-form" noValidate onSubmit={(event) => FormSubmitFunc(event, {
                    isSubmit: true
                })}>
                <Row className="my-4">
                    <Col lg={6} className="mb-4">
                        <div className="form-wrapper">
                            <label>Region Name *</label>                        
                            <input type="text" name="name" className={`form-control${formError?.name?.hasError ? ' pu-has-control-error': ''}`}
                                defaultValue={props?.EditItem?.name}
                                onChange={(event)=> HandleChangeFunc(event)} 
                                required={true}                           
                                placeholder="Enter region name here" />
                            {
                            ((formError?.name?.hasError && (formError?.name?.type === 'required')) && <div className="pu-has-error">
                                Region Name is required!
                            </div>)
                            }
                        </div>
                    </Col>                        
                </Row>
                <FormFooter parentPath= {props.parentPath} resetForm={ResetForm} handleSave={FormSubmitFunc}
                    saveButtonIsDisabled={!isEmpty(props?.EditItem?.published_at)}></FormFooter>
            </Form>
        </>
    )
}

const EditRegion = (props) => {
     return <CommonEditItemComp PuFormControlsEdit={
        (HandleSubmitFunc, SetPublishActionAvailability, item) => {
            console.log('EditRegion item', item);
            return (
                <>
                    <EditFormControls 
                        HandleSubmitFunc={HandleSubmitFunc}
                        SetPublishActionAvailability = {SetPublishActionAvailability}
                        EditItem={item} 
                        {...props}/>  
                </>
            )                 
        } 
    } {...props}/>
}

export default EditRegion;